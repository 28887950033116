import { useDayPicker } from 'react-day-picker'

export function CustomCalendarCaptionLabel(props) {
  const {
    locale,
    classNames,
    styles,
    formatters: { formatCaption }
  } = useDayPicker()
  return (
    <span
      className={classNames.caption_label}
      style={styles.caption_label}
      aria-live="polite"
      aria-atomic="true"
      id={props.id}
    >
      {formatCaption(props.displayMonth, { locale })}
    </span>
  )
}
