import { createContext, useContext } from 'react'

const SettingContext = createContext(undefined)

/**
 *
 * @param children
 * @param {SignalSettings} settings
 * @return {JSX.Element}
 * @constructor
 */
const SettingContextProvider = ({ children, settings }) => {
  const exportedValues = {
    settings
  }
  return (
    <SettingContext.Provider value={exportedValues}>
      {children}
    </SettingContext.Provider>
  )
}
export default SettingContextProvider

export const useSettingContext = () => {
  const context = useContext(SettingContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
