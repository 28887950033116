import AirConditionerIcon from 'public/property-icons/air-conditioner.svg'
import AlarmClockIcon from 'public/property-icons/alarm-clock.svg'
import ArcadeGamesIcon from 'public/property-icons/joystick.svg'
import BathIcon from 'public/property-icons/bath.svg'
import BathroomIcon from 'public/property-icons/bathroom.svg'
import BeachUmbrellaIcon from 'public/property-icons/beach-umbrella.svg'
import BedIcon from 'public/property-icons/bed.svg'
import BenchIcon from 'public/property-icons/bench.svg'
import BilliardIcon from 'public/property-icons/billiard.svg'
import BlenderIcon from 'public/property-icons/blender.svg'
import BluetoothSpeakerIcon from 'public/property-icons/portable-speaker.svg'
import BonfireIcon from 'public/property-icons/bonfire.svg'
import BoardGameIcon from 'public/property-icons/board-game.svg'
import BookIcon from 'public/property-icons/book.svg'
import BootDryerIcon from 'public/property-icons/boots.svg'
import BurnerIcon from 'public/property-icons/burner.svg'
import CalendarIcon from 'public/property-icons/calendar.svg'
import CampingTentIcon from 'public/property-icons/camping-tent.svg'
import ChefHatIcon from 'public/property-icons/chef-hat.svg'
import CoffeeMakerIcon from 'public/property-icons/coffee-maker.svg'
import CutleryIcon from 'public/property-icons/cutlery.svg'
import DetergentIcon from 'public/property-icons/detergent.svg'
import DishesIcon from 'public/property-icons/dishes.svg'
import EmailIcon from 'public/property-icons/email.svg'
import FanIcon from 'public/property-icons/fan.svg'
import FriendsIcon from 'public/property-icons/friends.svg'
import GarageIcon from 'public/property-icons/garages.svg'
import GolfIcon from 'public/property-icons/golf.svg'
import GondolaIcon from 'public/property-icons/gondola.svg'
import GrapesIcon from 'public/property-icons/grapes.svg'
import GrillIcon from 'public/property-icons/grill.svg'
import GymIcon from 'public/property-icons/gym.svg'
import HairdryerIcon from 'public/property-icons/hairdryer.svg'
import HeartIcon from 'public/property-icons/heart.svg'
import HighChairIcon from 'public/property-icons/high-chair.svg'
import HomeIcon from 'public/property-icons/home.svg'
import ImageIcon from 'public/property-icons/image.svg'
import JacuzziIcon from 'public/property-icons/jacuzzi.svg'
import KitchenToolIcon from 'public/property-icons/kitchen-tool.svg'
import KeurigIcon from 'public/property-icons/keurig.svg'
import LakeViewIcon from 'public/property-icons/lake.svg'
import LaundryIcon from 'public/property-icons/laundry.svg'
import LocationIcon from 'public/property-icons/location.svg'
import MountainViewIcon from 'public/property-icons/mountain.svg'
import OilHeaterIcon from 'public/property-icons/oil-heater.svg'
import OutdoorIcon from 'public/property-icons/outdoor.svg'
import OvenIcon from 'public/property-icons/oven.svg'
import PacknPlayIcon from 'public/property-icons/baby.svg'
import ParkingIcon from 'public/property-icons/parking.svg'
import PawprintIcon from 'public/property-icons/pawprint.svg'
import PoolTableIcon from 'public/property-icons/pooltable.svg'
import PrinterIcon from 'public/property-icons/printer.svg'
import RefrigeratorIcon from 'public/property-icons/refrigerator.svg'
import RubberDuckIcon from 'public/property-icons/rubber-duck.svg'
import SeaIcon from 'public/property-icons/sea.svg'
import SendIcon from 'public/property-icons/send.svg'
import SkiInSkiOutIcon from 'public/property-icons/ski.svg'
import SkiShuttleIcon from 'public/property-icons/bus.svg'
import SwimmingPoolIcon from 'public/property-icons/swimming-pool.svg'
import ToasterIcon from 'public/property-icons/toaster.svg'
import WheelchairIcon from 'public/property-icons/wheelchair.svg'
import WifiSignalIcon from 'public/property-icons/wifi-signal.svg'
import WineGlassIcon from 'public/property-icons/wine-glass.svg'
import WineIcon from 'public/property-icons/wine.svg'
import WineOpenerIcon from 'public/property-icons/wine-opener.svg'
import _3dIcon from 'public/property-icons/3d.svg'

const svgIconLookup = {
  '3d': _3dIcon,
  'air-conditioner': AirConditionerIcon,
  'alarm-clock': AlarmClockIcon,
  'arcade-games': ArcadeGamesIcon,
  'bath': BathIcon,
  'bathroom': BathroomIcon,
  'beach-umbrella': BeachUmbrellaIcon,
  'bed': BedIcon,
  'bench': BenchIcon,
  'billiard': BilliardIcon,
  'blender': BlenderIcon,
  'bluetooth-speaker': BluetoothSpeakerIcon,
  'board-game': BoardGameIcon,
  'bonfire': BonfireIcon,
  'book': BookIcon,
  'boots': BootDryerIcon,
  'burner': BurnerIcon,
  'calendar': CalendarIcon,
  'camping-tent': CampingTentIcon,
  'chef-hat': ChefHatIcon,
  'coffee-maker': CoffeeMakerIcon,
  'cutlery': CutleryIcon,
  'detergent': DetergentIcon,
  'dishes': DishesIcon,
  'email': EmailIcon,
  'fan': FanIcon,
  'friends': FriendsIcon,
  'garage': GarageIcon,
  'golf': GolfIcon,
  'gondola': GondolaIcon,
  'grapes': GrapesIcon,
  'grill': GrillIcon,
  'gym': GymIcon,
  'high-chair': HighChairIcon,
  'hairdryer': HairdryerIcon,
  'heart': HeartIcon,
  'image': ImageIcon,
  'jacuzzi': JacuzziIcon,
  'keurig': KeurigIcon,
  'kitchen-tool': KitchenToolIcon,
  'lake-view': LakeViewIcon,
  'laundry': LaundryIcon,
  'location': LocationIcon,
  'mountain-view': MountainViewIcon,
  'oil-heater': OilHeaterIcon,
  'outdoor': OutdoorIcon,
  'oven': OvenIcon,
  'pack-n-play': PacknPlayIcon,
  'parking': ParkingIcon,
  'pawprint': PawprintIcon,
  'pool-table': PoolTableIcon,
  'printer': PrinterIcon,
  'refrigerator': RefrigeratorIcon,
  'rubber-duck': RubberDuckIcon,
  'sea': SeaIcon,
  'send': SendIcon,
  'ski-in-ski-out': SkiInSkiOutIcon,
  'ski-shuttle': SkiShuttleIcon,
  'swimming-pool': SwimmingPoolIcon,
  'toaster': ToasterIcon,
  'wheelchair': WheelchairIcon,
  'wifi-signal': WifiSignalIcon,
  'wine': WineIcon,
  'wine-glass': WineGlassIcon,
  'wine-opener': WineOpenerIcon,
}

export const loadAmenityIcon = (iconString) => {
  if (iconString in svgIconLookup) return svgIconLookup[iconString]
  return HomeIcon
}
