import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'

/**
 *
 * @param {JSX.Element} LeftIcon
 * @param {String} title
 * @param {String} titleSize
 * @param {String} contentSize
 * @param {String} content
 * @param {Number} spacing
 * @param {Number} iconSize
 * @param {Object} colors
 * @returns {JSX.Element}
 * @constructor
 */

const IconTriggerButton = React.forwardRef(
  (
    {
      LeftIcon,
      title,
      titleSize = 'xs',
      contentSize = 'sm',
      content,
      iconSize = 5,
      colors,
      ...rest
    },
    ref
  ) => (
    <Button
      ref={ref}
      h="100%"
      alignItems={'center'}
      {...rest}
      gap={rest.gap ?? 2}
    >
      {LeftIcon && (
        <Icon
          as={LeftIcon}
          w={iconSize}
          fill={colors && colors.icon ? colors.icon : 'gray.700'}
        />
      )}
      <Flex flexDirection={'column'} justifyContent="flex-start">
        {title && (
          <Text
            fontWeight={'normal'}
            textAlign="left"
            fontSize={titleSize}
            color={colors && colors.title ? colors.title : 'gray.700'}
          >
            {title}
          </Text>
        )}
        {content && (
          <Text
            textAlign="left"
            fontSize={contentSize}
            fontWeight={'normal'}
            color={colors && colors.content ? colors.content : 'gray.700'}
          >
            {content}
          </Text>
        )}
      </Flex>
    </Button>
  )
)

IconTriggerButton.displayName = 'IconTriggerButton'

export default IconTriggerButton
